import { createClient } from '@supabase/supabase-js'
import type { PostgrestError } from '@supabase/supabase-js'

export const useFetchComposable = () => {
  const toast = useToast()
  const config = useRuntimeConfig()
  const client = useSupabaseClient()

  /**
   * ! Load Data !
   */

  const fetchData = (table: string, queryString: string, conditions: string, matchOpt?: string, matchOptVal?: string | number | boolean, secondMatchOpt?: string, secondMatchOptVal?: string | number | boolean, thirdMatchOpt?: string, thirdMatchOptVal?: string | number | boolean, exceptionOpt?: string, exceptionOptVal?: string | number | boolean) => {
    switch (conditions) {
      case 'single' :
        return fetchSingleData(table, queryString)
      case 'count' :
        return fetchCountData(table, queryString)
      case 'option' :
        return fetchOptionData(table, queryString, matchOpt ?? '', matchOptVal ?? '')
      case 'option-count' :
        return fetchOptionCountData(table, queryString, matchOpt ?? '', matchOptVal ?? '')
      case 'single-option' :
        return fetchOptionSingleData(table, queryString, matchOpt ?? '', matchOptVal ?? '')
      case 'double-option' :
        return fetchDoubleOptionData(table, queryString, matchOpt ?? '', matchOptVal ?? '', secondMatchOpt ?? '', secondMatchOptVal ?? '')
      case 'double-option-exception' :
        return fetchDoubleOptionExceptionData(table, queryString, matchOpt ?? '', matchOptVal ?? '', secondMatchOpt ?? '', secondMatchOptVal ?? '', exceptionOpt ?? '', exceptionOptVal ?? '')
      case 'double-option-single' :
        return fetchDoubleOptionSingleData(table, queryString, matchOpt ?? '', matchOptVal ?? '', secondMatchOpt ?? '', secondMatchOptVal ?? '')
      case 'double-option-count' :
        return fetchDoubleOptionCountData(table, queryString, matchOpt ?? '', matchOptVal ?? '', secondMatchOpt ?? '', secondMatchOptVal ?? '')
      case 'triple-option-single' :
        return fetchTripleOptionSingleData(table, queryString, matchOpt ?? '', matchOptVal ?? '', secondMatchOpt ?? '', secondMatchOptVal ?? '', thirdMatchOpt ?? '', thirdMatchOptVal ?? '')
      case 'default' :
        return fetchNormalData(table, queryString)
    }
  }

  const orderFetchData = async (table: string, queryString: string, orderType: string, orderValue: boolean, refrencedTable?: string, matchOpt?: string, matchOptVal?: string | number | boolean) => {
    let query = client
      .from(table)
      .select(queryString)
      .order(orderType, { ascending: orderValue })
      .eq('deleted', false)

    if (refrencedTable) {
      query = query.order(orderType, { referencedTable: refrencedTable, ascending: orderValue })
    }

    if (matchOpt && matchOptVal) {
      query = query.eq(matchOpt, matchOptVal)
    }

    const { data, error } = await query

    errorHandler('fetch Order Data', error)

    return data
  }

  const schemaFetchData = async (schema: string, table: string, queryString: string, customOrder?: string, customAscending?: boolean) => {
    const { data, error }: SerializeObject = await createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
      .from(table)
      .select(queryString)
      .order(customOrder ?? 'index', { ascending: customAscending ?? true })
      .eq('deleted', false)

    errorHandler('fetch schema Data', error)

    return data
  }

  const schemaFetchOptionData = async (schema: string, table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt?: string, secondMatchOptVal?: string | number | boolean, thirdMatchOpt?: string, thirdMatchOptVal?: string | number | boolean) => {
    const client = createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
    let query = client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq('deleted', false)

    if (secondMatchOpt) {
      query = query.eq(secondMatchOpt, secondMatchOptVal)
    }
    if (thirdMatchOpt) {
      query = query.eq(thirdMatchOpt, thirdMatchOptVal)
    }

    const { data, error }: SerializeObject = await query

    errorHandler('fetch schema option Data', error)

    return data
  }

  const schemaFetchOptionSortData = async (schema: string, table: string, queryString: string, customOrder: string, customAscending: boolean, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt?: string, secondMatchOptVal?: string | number | boolean) => {
    const client = createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
    let query = client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)

    if (secondMatchOpt) {
      query = query.eq(secondMatchOpt, secondMatchOptVal)
    }

    const { data, error }: SerializeObject = await query
      .order(customOrder ?? 'index', { ascending: customAscending ?? true })
      .eq('deleted', false)

    errorHandler('fetch schema option sort Data', error)

    return data
  }

  const schemaFetchOptionSingleData = async (schema: string, table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean) => {
    const { data, error }: SerializeObject = await createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq('deleted', false)
      .maybeSingle()

    errorHandler('fetch schema option single Data', error)

    return data
  }

  const schemaFetchOptionExceptionData = async (schema: string, table: string, queryString: string, exceptionOpt: string, exceptionOptVal: string | number | boolean, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean, thirdMatchOpt?: string, thirdMatchOptVal?: string | number | boolean, fourthMatchOpt?: string, fourthMatchOptVal?: string | number | boolean, secondExeptionOpt?: string, secondExeptionOptVal?: string | number | boolean) => {
    const client = createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
    let query = client
      .from(table)
      .select(queryString)
      .neq(exceptionOpt, exceptionOptVal)
      .eq('deleted', false)
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)

    if (secondExeptionOpt) {
      query = query.neq(secondExeptionOpt, secondExeptionOptVal)
    }

    if (thirdMatchOpt) {
      query = query.eq(thirdMatchOpt, thirdMatchOptVal)
    }
    if (fourthMatchOpt) {
      query = query.eq(fourthMatchOpt, fourthMatchOptVal)
    }

    const { data, error }: SerializeObject = await query

    errorHandler('fetch schema option Data with exception', error)

    return data
  }

  const schemaFetchOptionRangeSingleData = async (schema: string, table: string, queryString: string, greatOpt: string, greatVal: string | number, lessOpt: string, lessVal: string | number) => {
    const { data, error }: SerializeObject = await createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
      .from(table)
      .select(queryString)
      .lt(lessOpt, lessVal)
      .gt(greatOpt, greatVal)
      .maybeSingle()

    errorHandler('fetch schema range single Data', error)

    return data
  }

  const schemaFetchRangeData = async (schema: string, table: string, queryString: string, rangeStart: number, rangeEnd: number, customOrder?: string, customAscending?: boolean, matchOpt?: string, matchOptVal?: string | number | boolean, secondMatchOpt?: string, secondMatchOptVal?: string | number | boolean) => {
    const client = createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
    let query = client
      .from(table)
      .select(queryString, { count: 'exact' })
      .range(rangeStart, rangeEnd)
      .order(customOrder ?? 'created_at', { ascending: customAscending ?? false })
      .eq('deleted', false)

    if (matchOpt) {
      query = query.eq(matchOpt, matchOptVal)
    }
    if (secondMatchOpt) {
      query = query.eq(secondMatchOpt, secondMatchOptVal)
    }

    const { data, count, error }: SerializeObject = await query

    errorHandler('fetch schema range Data', error)

    return secondMatchOpt || matchOpt ? { data, count } : data
  }

  const schemaFetchMultipleData = async (schema: string, table: string, queryString: string, option: string, multipleVal: string[]) => {
    const { data, error }: SerializeObject = await createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
      .from(table)
      .select(queryString)
      .in(option, multipleVal)

    errorHandler('fetch schema multiple Data', error)

    return data
  }

  const schemaFetchMultipleCount = async (schema: string, table: string, queryString: string, option: string, multipleVal: string[], matchOpt?: string, matchOptVal?: string | number | boolean) => {
    const client = await createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })

    let query = client
      .from(table)
      .select(queryString, { count: 'exact' })
      .in(option, multipleVal)

    if (matchOpt) {
      query = query.eq(matchOpt, matchOptVal)
    }

    const { count, error }: SerializeObject = await query

    errorHandler('fetch schema multiple Count', error)

    return count
  }

  const fetchNormalData = async (table: string, queryString: string) => {
    const { data, error }: SerializeObject = await client
      .from(table)
      .select(queryString)
      .eq('deleted', false)

    errorHandler('fetch Normal Data', error)

    return data
  }

  const fetchCountData = async (table: string, queryString: string) => {
    const { data, count, error } = await client
      .from(table)
      .select(queryString, { count: 'exact' })

    errorHandler('fetch Data with count', error)

    return { fetchData: data, count }
  }

  const fetchSingleData = async (table: string, queryString: string) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .maybeSingle()

    errorHandler('fetch SingleData with count', error)

    return data
  }

  const fetchOptionData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq('deleted', false)

    errorHandler('fetch OptionData', error)

    return data
  }

  const fetchOptionSingleData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .maybeSingle()

    errorHandler('fetch OptionData Single', error)

    return data
  }

  const fetchOptionCountData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean) => {
    const { data, count, error } = await client
      .from(table)
      .select(queryString, { count: 'exact' })
      .eq(matchOpt, matchOptVal)

    errorHandler('fetch OptionData with count', error)

    return { fetchData: data, count }
  }

  const fetchDoubleOptionData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)
      .eq('deleted', false)

    errorHandler('fetch Double OptionData', error)

    return data
  }

  const fetchDoubleOptionSingleData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)
      .maybeSingle()

    errorHandler('fetch Double OptionData Single', error)

    return data
  }

  const fetchDoubleOptionExceptionData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean, exceptionOpt: string, exceptionOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)
      .neq(exceptionOpt, exceptionOptVal)

    errorHandler('fetch Double OptionData with exception', error)

    return data
  }

  const fetchDoubleOptionCountData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean) => {
    const { data, count, error } = await client
      .from(table)
      .select(queryString, { count: 'exact' })
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)

    errorHandler('fetch Double OptionData with count', error)

    return { fetchData: data, count }
  }

  const fetchTripleOptionSingleData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string | number | boolean, secondMatchOpt: string, secondMatchOptVal: string | number | boolean, thirdMatchOpt: string, thirdMatchOptVal: string | number | boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .eq(matchOpt, matchOptVal)
      .eq(secondMatchOpt, secondMatchOptVal)
      .eq(thirdMatchOpt, thirdMatchOptVal)

    errorHandler('fetch Triple OptionData with count', error)

    return data
  }

  const schemaFetchCountOnlyData = async (schema: string, table: string, matchOpt?: string, matchOptVal?: string | number | boolean, secondMatchOpt?: string, secondMatchOptVal?: string | number | boolean, thirdMatchOpt?: string, thirdMatchOptVal?: string | number | boolean, firstTextSearchOption?: string, firstTextSearchQuery?: string, secondTextSearchOption?: string, secondTextSearchQuery?: string) => {
    const client = createClient(config.public.supabaseUrl, config.public.supabaseKey, { db: { schema } })
    let query = client
      .from(table)
      .select('id', { count: 'exact', head: true })

    if (firstTextSearchOption) {
      query = query.ilike(firstTextSearchOption, `%${firstTextSearchQuery}%`)
    }
    if (secondTextSearchOption) {
      query = query.ilike(secondTextSearchOption, `%${secondTextSearchQuery}%`)
    }

    if (matchOpt) {
      query = query.eq(matchOpt, matchOptVal)
    }
    if (secondMatchOpt) {
      query = query.eq(secondMatchOpt, secondMatchOptVal)
    }
    if (thirdMatchOpt) {
      query = query.eq(thirdMatchOpt, thirdMatchOptVal)
    }

    const { count, error }: SerializeObject = await query

    errorHandler('fetch only count', error)

    return count
  }

  const fetchColumnArrayData = async (table: string, queryString: string, matchOpt: string, matchOptVal: string[] | number[] | boolean[], customOrder?: string, customAscending?: boolean) => {
    const { data, error } = await client
      .from(table)
      .select(queryString)
      .in(matchOpt, matchOptVal)
      .order(customOrder ?? 'created_at', { ascending: customAscending ?? false })

    errorHandler('fetch Column Array Data', error)

    return data
  }

  const insertData = async (table: string, insertData: SerializeObject, returnValue: boolean) => {
    if (returnValue) {
      const { data, error } = await client
        .from(table)
        .insert(insertData)
        .select()
        .single()

      errorHandler('insertData', error)

      return data as SerializeObject
    }
    else {
      const { error } = await client
        .from(table)
        .insert(insertData)

      errorHandler('insertData', error)

      return error
    }
  }

  const errorHandler = (fetchName: string, error: PostgrestError | null) => {
    if (error) {
      toast.add({ title: error.message, description: `at ${fetchName}`, color: 'rose', timeout: 2000 })
    }
  }

  // const deleteData = async (table: string, admin: boolean, idOpt: string, deleteId: string, matOpt: string, matOptVal: string, subMatOpt: string, subMatOptVal: string) => {
  //   if (admin) {
  //     const { error } = await client
  //       .from(table)
  //       .delete()
  //       .eq(idOpt, deleteId)
  //       .eq(matOpt, matOptVal)

  //     if (error) {
  //       toast.add({ title: error.message, description: 'at deleteData using match option', color: 'rose', timeout: 2000 })
  //     }
  //   }
  //   else if (matOpt && subMatOpt) {
  //     const { error } = await client
  //       .from(table)
  //       .delete()
  //       .eq('id', deleteId)
  //       .eq(matOpt, matOptVal)
  //       .eq(subMatOpt, subMatOptVal)

  //     if (error) {
  //       toast.add({ title: error.message, description: 'at deleteData using match option and sub match option', color: 'rose', timeout: 2000 })
  //     }
  //   }
  //   else {
  //     const { error } = await client
  //       .from(table)
  //       .delete()
  //       .eq('id', deleteId)

  //     if (error) {
  //       toast.add({ title: error.message, description: 'at deleteData without match option', color: 'rose', timeout: 2000 })
  //     }
  //   }
  // }

  const logout = async () => {
    await client.auth.signOut()
  }

  return {
    fetchData,
    orderFetchData,
    schemaFetchData,
    schemaFetchOptionData,
    schemaFetchOptionSortData,
    schemaFetchOptionSingleData,
    schemaFetchOptionExceptionData,
    schemaFetchOptionRangeSingleData,
    schemaFetchRangeData,
    schemaFetchMultipleData,
    schemaFetchMultipleCount,
    schemaFetchCountOnlyData,
    fetchColumnArrayData,
    insertData,
    // deleteData,
    logout,
  }
}
