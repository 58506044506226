import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/app/middleware/auth.ts"),
  "clear-article-page": () => import("/vercel/path0/app/middleware/clearArticlePage.ts"),
  "clear-search-model": () => import("/vercel/path0/app/middleware/clearSearchModel.ts"),
  "first-auth": () => import("/vercel/path0/app/middleware/firstAuth.ts"),
  "main-auth": () => import("/vercel/path0/app/middleware/mainAuth.ts")
}