
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqtWdFvmbYwGPY1dHt46ULNUreZQgYiWMec3Usk7Da298Meta } from "/vercel/path0/app/pages/faq.vue?macro=true";
import { default as indexXNEFQ_45BjAwM6CKVywW7WXWaOQOf9WzaBic0wLTXHAssMeta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as pinuwVkTumDWGuEcTTwzqtKBu1ag1j38qxZgoGp5NT2KNUMeta } from "/vercel/path0/app/pages/my/pin.vue?macro=true";
import { default as confirmDMGxLvu64ZVWvqMiQgtpXZ5V_Pn_457OVfBon36zdgA6AMeta } from "/vercel/path0/app/pages/confirm.vue?macro=true";
import { default as keept8sRjWnhpxDnfQ5KIcv_GwoiiOooQmbp3NxFKl8JDW8Meta } from "/vercel/path0/app/pages/my/keep.vue?macro=true";
import { default as alarmCehyi6Yh7zun7nNMfcWyciNhgSRmd0UeXlNxIpgmHgwMeta } from "/vercel/path0/app/pages/my/alarm.vue?macro=true";
import { default as board__vSnb0x_wFy_LDcEPINTCFKHeHWz0zAPsaOe4ChQnIMeta } from "/vercel/path0/app/pages/my/board.vue?macro=true";
import { default as pointNg_45byr2mvA2YQsR7IUmnOQ0UhnVZ31PSn9uK7uYWgF0Meta } from "/vercel/path0/app/pages/my/point.vue?macro=true";
import { default as skillJ0SLFVIGVGQ4HtD4x0t2Y54GH5ATdzTkstX6DK8FhdgMeta } from "/vercel/path0/app/pages/my/skill.vue?macro=true";
import { default as sparkV0Gk7aRftfOXU1kaaG3nB_8gRZ0SzR1T16sxBYTLOXkMeta } from "/vercel/path0/app/pages/my/spark.vue?macro=true";
import { default as newS_456yxbX57OyqwDPIsLc6Aoy_45qbfe7CA3IbZno7ieN7QMeta } from "/vercel/path0/app/pages/login/new.vue?macro=true";
import { default as couponhrpA5IWqZUZNViclqunxhP7y9O5Eu410gHL9KL_45XbC8Meta } from "/vercel/path0/app/pages/my/coupon.vue?macro=true";
import { default as marketeH2b04wZiQaLGaeI41zCC9zTRrW4F9g7HbVxSVGXsKIMeta } from "/vercel/path0/app/pages/my/market.vue?macro=true";
import { default as reviewzmdUzwt1o2IdOwasCzT2BdaKUP5suWhZY2EM21ukg_450Meta } from "/vercel/path0/app/pages/my/review.vue?macro=true";
import { default as commentQzgF9JAPmHKxP5GrYtjv8cJggXaCfFy4147qpRpkRZIMeta } from "/vercel/path0/app/pages/my/comment.vue?macro=true";
import { default as meetingyrs0gsLJLLueqqIocv6XlGA3DRGHNlyx4J23hdfWL4kMeta } from "/vercel/path0/app/pages/my/meeting.vue?macro=true";
import { default as writeOg5q0dvMGOWmW_Odcj2A3JyLd50iOKrsEkojb3kRbC8Meta } from "/vercel/path0/app/pages/board/write.vue?macro=true";
import { default as indexwIncfyYip7RrsPdDa_SGfTEnWY40YsNbDb9viTMhCWgMeta } from "/vercel/path0/app/pages/login/index.vue?macro=true";
import { default as naverUyWjMTGXO1lHnVortPXEDdNLIGcWduYjS6ass9a1K_IMeta } from "/vercel/path0/app/pages/login/naver.vue?macro=true";
import { default as feedbackvY80OWbLE9n9FOQ4FB0mNB20qSfM99JgSnvTTpcJi5AMeta } from "/vercel/path0/app/pages/my/feedback.vue?macro=true";
import { default as indexu_45W5i3JobBgLaw0ewTMhFgi8CPY4Be8wqeMl0jya9ZIMeta } from "/vercel/path0/app/pages/point/index.vue?macro=true";
import { default as writeXgKVtlXw6jq0JnZmPPLKwpiaW84dGmYSmnQMJ_mximAMeta } from "/vercel/path0/app/pages/market/write.vue?macro=true";
import { default as boardGPy6O31_2IA6SiLPCDdq6U6uxT56hCWHANpLvWfFNx8Meta } from "/vercel/path0/app/pages/policy/board.vue?macro=true";
import { default as indexfv7fBoHtrLg7R3EtlrtAfExSzHxvoVeqhLXzNnMbO44Meta } from "/vercel/path0/app/pages/policy/index.vue?macro=true";
import { default as termsfGx4kPNFDlS64lNzei5KnJ9eATXZfFa8_8CtDEYPfA8Meta } from "/vercel/path0/app/pages/policy/terms.vue?macro=true";
import { default as _91id_934_45Ooi66zpmLy8XV5YBzjsJXWSGIgfxD9xt9av_81P9YMeta } from "/vercel/path0/app/pages/profile/[id].vue?macro=true";
import { default as indexPuZtgQbFObXZ8zAW4bf6IIt86aFrik78ukTQU6ESjj8Meta } from "/vercel/path0/app/pages/search/index.vue?macro=true";
import { default as welcomegpn1W6iZJzmSxUd0NegkrFx_skEId1eOglDCBaG25ucMeta } from "/vercel/path0/app/pages/login/welcome.vue?macro=true";
import { default as marketjk2XZnZSvpxEEow6WxW_vVCgksA35Oc45sqtupgWFIcMeta } from "/vercel/path0/app/pages/policy/market.vue?macro=true";
import { default as index_kw1rQcClzTz_45Ni57ggQSnV1YrQxxhFpcNJAbvPf5OsMeta } from "/vercel/path0/app/pages/profile/index.vue?macro=true";
import { default as resultTe72kzvA3yGVQgEKXSaxd7M4dMwgJBOP4rC_7PMfgccMeta } from "/vercel/path0/app/pages/search/result.vue?macro=true";
import { default as alarm12S7_EgR41iwtbDp68CivysDZRoYdUzDLfiK3f3q4ooMeta } from "/vercel/path0/app/pages/setting/alarm.vue?macro=true";
import { default as blindjbvN7Ux6pOBsCjVdyOE1omH4VSdU86kgpNL1gJzf044Meta } from "/vercel/path0/app/pages/setting/blind.vue?macro=true";
import { default as indexJNKwKXFqsUkJqwguhAtv9WOtQY_45HXqWBUtZIIeXIpFYMeta } from "/vercel/path0/app/pages/setting/index.vue?macro=true";
import { default as index88BdM0rt4MPOSJZg_45aAd6OnBesD0G_2oD0w44X9i_OMMeta } from "/vercel/path0/app/pages/announce/index.vue?macro=true";
import { default as privacyQW2ZOiMhFrigisXlEfgPPU84H4kkVHlrqHop96TKh5IMeta } from "/vercel/path0/app/pages/policy/privacy.vue?macro=true";
import { default as indexHTYRa6RXo0T88aOceLr2pkD8YwWJ7eJd4oV03XJi5mEMeta } from "/vercel/path0/app/pages/board/qna/index.vue?macro=true";
import { default as indexHsicThhnfkEb_cIqpLGwOpK_Q1IwdQJqC84O2xr0_DAMeta } from "/vercel/path0/app/pages/board/free/index.vue?macro=true";
import { default as withdrawal5gFwBqJEuIfdwK7PGXR2R4mAsCVHpDDarYNRVadUUaYMeta } from "/vercel/path0/app/pages/login/withdrawal.vue?macro=true";
import { default as indexpHKH5vhC2WG7b7I7LA9Mu4POrDRh9NIpyyPswtzetTgMeta } from "/vercel/path0/app/pages/market/buy/index.vue?macro=true";
import { default as feedbackBUPABq1fIH_O9L9H_45wFBj1vZfFamFa9gZFPFe4fiyQYMeta } from "/vercel/path0/app/pages/meeting/feedback.vue?macro=true";
import { default as securityXSmX32MYYpwRtWjqlQVQJuf3Cqi9YFlqrKLLvzbZ3G4Meta } from "/vercel/path0/app/pages/setting/security.vue?macro=true";
import { default as indexiEooWQZ_45ONnU1NvOza4DOqLwKPV_N1_MrjT4RrEjG2oMeta } from "/vercel/path0/app/pages/settlement/index.vue?macro=true";
import { default as indexYsNjBzzcQbAgx3aWuJJqHm8JTeVsN_45VNeMulw6iR0aoMeta } from "/vercel/path0/app/pages/board/issue/index.vue?macro=true";
import { default as indexxB5XHni1_45sUdXd7YGDfnRCfup9tNyVq4_nngcBh8cLkMeta } from "/vercel/path0/app/pages/board/route/index.vue?macro=true";
import { default as indexOxKWra_hWkAeuwSkdcDwba5JHy5qgVit25hBTkCUrXQMeta } from "/vercel/path0/app/pages/board/total/index.vue?macro=true";
import { default as indexYY_45JUEEhC1eKkDSChCiUt_WTP11Mi5jneK01PewrpLQMeta } from "/vercel/path0/app/pages/market/gear/index.vue?macro=true";
import { default as indexZqOSbabISrBWKNJNHl2ju3L2Ek97mysA_8KjQQkeaN4Meta } from "/vercel/path0/app/pages/review/gear/index.vue?macro=true";
import { default as indexJh9urQDS1tifLIf8o_CC_45cb7mIvfEfB0rG6EIxgtKUEMeta } from "/vercel/path0/app/pages/board/riding/index.vue?macro=true";
import { default as indexiOkmEXYS1TcUox9neze1TUcxbYLUoJd64Yr7hSExHcQMeta } from "/vercel/path0/app/pages/board/tuning/index.vue?macro=true";
import { default as indexzf_vMLDeJTX9f5HxQ6RmStk6biX_fC4zMHH4m79gs7wMeta } from "/vercel/path0/app/pages/market/parts/index.vue?macro=true";
import { default as indexSA0cbcvVLQ4acoK9R3lGwrum_JslXk1MDZWBKcAl_45aUMeta } from "/vercel/path0/app/pages/market/total/index.vue?macro=true";
import { default as chat8qtTetzfFw7WnsUOiBo8gGpASuedeCLHubgx6tDzLJwMeta } from "/vercel/path0/app/pages/meeting/owner/chat.vue?macro=true";
import { default as result6h0tFvX0euAyBzr1yk8TBnnHhsEebNjz3nZG3EUhd_458Meta } from "/vercel/path0/app/pages/specCompare/result.vue?macro=true";
import { default as searchkjUDWWRodQOQQ3aEUTfS5uGf_45xFt6J0envuR4x6Isw0Meta } from "/vercel/path0/app/pages/specCompare/search.vue?macro=true";
import { default as select_45IVa9SwIThoBHBB1v5A_45DwwOqRdbyFfOMPRTpwmDwe8Meta } from "/vercel/path0/app/pages/specCompare/select.vue?macro=true";
import { default as indexKnKxNRtApof8CXYxJoIJ4RIDdXDoLrCknCvgbllMhDEMeta } from "/vercel/path0/app/pages/board/gallery/index.vue?macro=true";
import { default as _91boardId_93_e_45FID1cJF827_45kGqS038bm1Ye50tnruIibMeCfRD7cMeta } from "/vercel/path0/app/pages/board/qna/[boardId].vue?macro=true";
import { default as indexgjhmNgS9IgHKG_JxLARqnkd79mECdrGrR7_yhfEKPZEMeta } from "/vercel/path0/app/pages/meeting/owner/index.vue?macro=true";
import { default as chatCpgRfoVWwBcZP2she00k4kLNuT3pr3kCYR_45i1eJ5xD4Meta } from "/vercel/path0/app/pages/meeting/pickup/chat.vue?macro=true";
import { default as chatI1__cvIo_45bAsiVmP6_45s37SDpqtL_459EdK7ZZQjJeVvq0Meta } from "/vercel/path0/app/pages/meeting/repair/chat.vue?macro=true";
import { default as chat2eg0VyuWAKdx0lVk7J6xDzEQJhimYpHsJWXgKLPFdAcMeta } from "/vercel/path0/app/pages/meeting/rescue/chat.vue?macro=true";
import { default as indexwgaqbYvytVz_nhWhWeqjOyLo0ikKMhCc4M2eoAzEGq8Meta } from "/vercel/path0/app/pages/board/business/index.vue?macro=true";
import { default as _91boardId_93gIYoMNXcw1uEf8ZhWlj_45KnGIw8UMby9W4vHcdPD3uZcMeta } from "/vercel/path0/app/pages/board/free/[boardId].vue?macro=true";
import { default as _91boardId_93_YiBeoCyqNECWw2GCtOYWmolvyC_45qNtbpG2i54b_JfsMeta } from "/vercel/path0/app/pages/market/buy/[boardId].vue?macro=true";
import { default as searchmqWKomEAMyhdj__TbtEqXUlKAb3MAg_45WCWJLbX_45uaAIMeta } from "/vercel/path0/app/pages/meeting/owner/search.vue?macro=true";
import { default as indexsad1yqZhD0iJldGIdhp_45ybJzFxs_a6wDSvceGWDMtJMMeta } from "/vercel/path0/app/pages/meeting/pickup/index.vue?macro=true";
import { default as indexvZg1eHSPxmt85Z8sSbBU1om_45r7ZVqxEWjU0oqNn_458loMeta } from "/vercel/path0/app/pages/meeting/repair/index.vue?macro=true";
import { default as indexY_iT7lz52UvJ_t68mFUhLLHS8QRgNzIGi6KZ0dCsfgIMeta } from "/vercel/path0/app/pages/meeting/rescue/index.vue?macro=true";
import { default as indexnPDIRzMQIDXSvAcGNIPbpPd4CJOdYX8n1PZY7YyHfq4Meta } from "/vercel/path0/app/pages/board/guestbook/index.vue?macro=true";
import { default as writeQPNDI2yTCWdKAstMj8t7dfSrplD26GY89fHIvkHlqMAMeta } from "/vercel/path0/app/pages/board/guestbook/write.vue?macro=true";
import { default as _91boardId_93wmRNSlBTlEqxGZPQUsxjjdFjPuIrq5EkshuNwXwlgFMMeta } from "/vercel/path0/app/pages/board/issue/[boardId].vue?macro=true";
import { default as _91boardId_93okuktC8FZPGX62MwR4CTEvplRi3dQJ0bxfNnO5__45WUsMeta } from "/vercel/path0/app/pages/board/route/[boardId].vue?macro=true";
import { default as _91boardId_93sOg8x4tqUPtO6UB47lLCQmGoWshIF2NR4Aaqy5lsuCkMeta } from "/vercel/path0/app/pages/board/total/[boardId].vue?macro=true";
import { default as _91boardId_93T4zqi2gf_S4Bc1_S7Ha5vyOufNwYzKIKaCMpFhU5ohEMeta } from "/vercel/path0/app/pages/market/gear/[boardId].vue?macro=true";
import { default as chataZ0Ii9K33X2GAh4ePr8BFT913f2nqlC_cCi6qXlf6vcMeta } from "/vercel/path0/app/pages/meeting/coaching/chat.vue?macro=true";
import { default as searchpr_YkW2SdyBytpnAnaLQl6KxM_LG4g2R6L5p8H3IHYsMeta } from "/vercel/path0/app/pages/meeting/pickup/search.vue?macro=true";
import { default as search5Je6SctjvGzPNyxKSHqbyP0U_Q4sKPoUmOwBFJzX7z0Meta } from "/vercel/path0/app/pages/meeting/repair/search.vue?macro=true";
import { default as search8tB3NxaEaymmFYKiNAqDbbfOnCLNOhz43m5eHX8SOhEMeta } from "/vercel/path0/app/pages/meeting/rescue/search.vue?macro=true";
import { default as hotPS2qhSwAxQufTyXa0qracJyb9nomQYjEq5ukKQ1ZN9oMeta } from "/vercel/path0/app/pages/review/motorcycle/hot.vue?macro=true";
import { default as _91boardId_93JbcOA7DPGhEIPdpyIkYUQcRF_nlyNtA6TvR0B3GKZ8UMeta } from "/vercel/path0/app/pages/board/riding/[boardId].vue?macro=true";
import { default as _91boardId_93qDc0jMXRUAi02YUkbWe_453xyqAYbEy3tmt8sU6rl2_45J0Meta } from "/vercel/path0/app/pages/board/tuning/[boardId].vue?macro=true";
import { default as _91boardId_93ywsGw9d6hDZyARdGkwFd53_45hmZvnspnHviT9JQqOv_QMeta } from "/vercel/path0/app/pages/market/parts/[boardId].vue?macro=true";
import { default as _91boardId_93rNCKPgV5S9Z4FtOv1PePbY1nZROWiaU1EeMD2pk69MsMeta } from "/vercel/path0/app/pages/market/total/[boardId].vue?macro=true";
import { default as indexO7PVxPqMUQM6Vn5i6XFE7aFfskW7mcCRr7frnIVpqbEMeta } from "/vercel/path0/app/pages/meeting/coaching/index.vue?macro=true";
import { default as listQsCj1zUhb0L_45H_453dzKduKjVRsiPFjCC93J_Yf1VbuncMeta } from "/vercel/path0/app/pages/review/motorcycle/list.vue?macro=true";
import { default as _91boardId_93nqdAyAk_45NGRnJmFgjZLtoHCHPaRhQQF6wpsfjQuoVL8Meta } from "/vercel/path0/app/pages/board/gallery/[boardId].vue?macro=true";
import { default as indexbQCZ72ZkhgPMgXqJ_ngkCUIOmpnG1cW0JdUmaVGOuGoMeta } from "/vercel/path0/app/pages/board/reportTheft/index.vue?macro=true";
import { default as indexD3dm4AbPfh3RRlAkSMh4PoTxMG2zMclcto16eYSzraMMeta } from "/vercel/path0/app/pages/board/vehicleCare/index.vue?macro=true";
import { default as searchfuYWOKX_45xHFwlIofDaFRBC5QBGSg7LmMCzR_45mu64s2oMeta } from "/vercel/path0/app/pages/meeting/coaching/search.vue?macro=true";
import { default as indexmTH4kDdn5f1iUOMW5R81f89yvsLW92DTlGLREt1AqLgMeta } from "/vercel/path0/app/pages/report/motorcycle/index.vue?macro=true";
import { default as _91boardId_93jUB6xqT5ysdAUrBcr_oo76t9NsUq16uECE3ledmufn4Meta } from "/vercel/path0/app/pages/announce/[tab]/[boardId].vue?macro=true";
import { default as _91boardId_93wqNWGcTFgIZzfOGHEoIgDyiWMsL3Jbm_aT4Vatd_n2MMeta } from "/vercel/path0/app/pages/board/business/[boardId].vue?macro=true";
import { default as detaily7NreWLVZNLl9jfgafZdOpvQeANgg9QefQOBtvObs_0Meta } from "/vercel/path0/app/pages/review/motorcycle/detail.vue?macro=true";
import { default as securityCheckpuoiT79gZfsAr18K2b5Nb8Lt_LuvqfbgY0xA144LbEkMeta } from "/vercel/path0/app/pages/settlement/securityCheck.vue?macro=true";
import { default as _91boardId_93mDFaYD71L_45hwjyX7gDYtAzKU1S4RlHEz0DmV9TASRu4Meta } from "/vercel/path0/app/pages/board/guestbook/[boardId].vue?macro=true";
import { default as index6UVZO7Yh61CTP6Te4cbcpiEaDsMPmB8JFp7rhypnhBwMeta } from "/vercel/path0/app/pages/market/deliveryGear/index.vue?macro=true";
import { default as indexcBwlO9LeYBrb7Mx1p3U8Q6t9OrAQh_45j0c2riNzdHxhoMeta } from "/vercel/path0/app/pages/market/over125Manual/index.vue?macro=true";
import { default as indexkq2wt3ql4JabFtBRcAvDYDfWqJweMuHeolG4vdG8U08Meta } from "/vercel/path0/app/pages/review/motorcycle/my/index.vue?macro=true";
import { default as _91boardId_93VGLuYa7yUR4xqqK2Z_1_456CPBcy84rIBuJav32GyLr_454Meta } from "/vercel/path0/app/pages/board/reportTheft/[boardId].vue?macro=true";
import { default as _91boardId_93OJ3RhJciwdnTw_45N4RevvGr5e5Q0lLXxqWVxqxAFAOVIMeta } from "/vercel/path0/app/pages/board/vehicleCare/[boardId].vue?macro=true";
import { default as indextLuPwmGKugX8zL_45ZpHa6C6iPWVdBpefQKyp2EHlQ0z4Meta } from "/vercel/path0/app/pages/market/over125Scooter/index.vue?macro=true";
import { default as indexYqpa2e6Or8kIW2pLPdRnMZA4_45Zjofv4TZkwoPjq8VZgMeta } from "/vercel/path0/app/pages/market/under125Manual/index.vue?macro=true";
import { default as indexyH0_pbqAmls4evaZoPoQxoZywn1ZKnpSvtMTPOx_Kx4Meta } from "/vercel/path0/app/pages/market/under125Scooter/index.vue?macro=true";
import { default as initKK5Ul_YVHl4M5ejGyTgppN0u8vXmRkWCl0qkDC_451qeIMeta } from "/vercel/path0/app/pages/review/motorcycle/write/init.vue?macro=true";
import { default as _91boardId_93LuPAKiQC08hBoNWaHGZ7m8PKGYjhMTz__V4xdNeWgssMeta } from "/vercel/path0/app/pages/market/deliveryGear/[boardId].vue?macro=true";
import { default as index_45vACxsJad0hd9_YuF_450upPMlW8rUjSURqn16IVz3KOgMeta } from "/vercel/path0/app/pages/review/motorcycle/write/index.vue?macro=true";
import { default as _91boardId_937koiXbAFHXPyrq3MfCOKPgdJeXpWgS31pOKoJaco0k4Meta } from "/vercel/path0/app/pages/market/over125Manual/[boardId].vue?macro=true";
import { default as index5qdrKX70YK0whlciduWi1yY1nzYr6tBU6oRb6bFq0GwMeta } from "/vercel/path0/app/pages/review/motorcycle/search/index.vue?macro=true";
import { default as searchoJ7tcTCiufPxuj1rMbiHsRahuTyWVNTTZBOOk86ClJ4Meta } from "/vercel/path0/app/pages/review/motorcycle/write/search.vue?macro=true";
import { default as _91boardId_93yeDc_aHVDh4GguBVEbiP4_45ccWvKgxpHV5pkbduYwhi4Meta } from "/vercel/path0/app/pages/market/over125Scooter/[boardId].vue?macro=true";
import { default as _91boardId_93Vi6sSc7idQjF_450j1aqYIqgl3Sf7JksP0W_X7jSqP7vwMeta } from "/vercel/path0/app/pages/market/under125Manual/[boardId].vue?macro=true";
import { default as resultatKkrtMx_Dqx3HMV0d5S6YTbLfH7Gacln0ZkpRrQIjYMeta } from "/vercel/path0/app/pages/review/motorcycle/search/result.vue?macro=true";
import { default as confirmyJ8etEZvIRvl6s799wHRikQ5PpBB6tRVccdHJFx5KBUMeta } from "/vercel/path0/app/pages/review/motorcycle/write/confirm.vue?macro=true";
import { default as _91boardId_93SBPtCc9qEkpu3WcpkXFjRQZVXbFejqbVmG6pLPaFvxkMeta } from "/vercel/path0/app/pages/market/under125Scooter/[boardId].vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    meta: faqtWdFvmbYwGPY1dHt46ULNUreZQgYiWMec3Usk7Da298Meta || {},
    component: () => import("/vercel/path0/app/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXNEFQ_45BjAwM6CKVywW7WXWaOQOf9WzaBic0wLTXHAssMeta || {},
    component: () => import("/vercel/path0/app/pages/index.vue")
  },
  {
    name: "my-pin",
    path: "/my/pin",
    meta: pinuwVkTumDWGuEcTTwzqtKBu1ag1j38qxZgoGp5NT2KNUMeta || {},
    component: () => import("/vercel/path0/app/pages/my/pin.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmDMGxLvu64ZVWvqMiQgtpXZ5V_Pn_457OVfBon36zdgA6AMeta || {},
    component: () => import("/vercel/path0/app/pages/confirm.vue")
  },
  {
    name: "my-keep",
    path: "/my/keep",
    meta: keept8sRjWnhpxDnfQ5KIcv_GwoiiOooQmbp3NxFKl8JDW8Meta || {},
    component: () => import("/vercel/path0/app/pages/my/keep.vue")
  },
  {
    name: "my-alarm",
    path: "/my/alarm",
    meta: alarmCehyi6Yh7zun7nNMfcWyciNhgSRmd0UeXlNxIpgmHgwMeta || {},
    component: () => import("/vercel/path0/app/pages/my/alarm.vue")
  },
  {
    name: "my-board",
    path: "/my/board",
    meta: board__vSnb0x_wFy_LDcEPINTCFKHeHWz0zAPsaOe4ChQnIMeta || {},
    component: () => import("/vercel/path0/app/pages/my/board.vue")
  },
  {
    name: "my-point",
    path: "/my/point",
    meta: pointNg_45byr2mvA2YQsR7IUmnOQ0UhnVZ31PSn9uK7uYWgF0Meta || {},
    component: () => import("/vercel/path0/app/pages/my/point.vue")
  },
  {
    name: "my-skill",
    path: "/my/skill",
    meta: skillJ0SLFVIGVGQ4HtD4x0t2Y54GH5ATdzTkstX6DK8FhdgMeta || {},
    component: () => import("/vercel/path0/app/pages/my/skill.vue")
  },
  {
    name: "my-spark",
    path: "/my/spark",
    meta: sparkV0Gk7aRftfOXU1kaaG3nB_8gRZ0SzR1T16sxBYTLOXkMeta || {},
    component: () => import("/vercel/path0/app/pages/my/spark.vue")
  },
  {
    name: "login-new",
    path: "/login/new",
    meta: newS_456yxbX57OyqwDPIsLc6Aoy_45qbfe7CA3IbZno7ieN7QMeta || {},
    component: () => import("/vercel/path0/app/pages/login/new.vue")
  },
  {
    name: "my-coupon",
    path: "/my/coupon",
    meta: couponhrpA5IWqZUZNViclqunxhP7y9O5Eu410gHL9KL_45XbC8Meta || {},
    component: () => import("/vercel/path0/app/pages/my/coupon.vue")
  },
  {
    name: "my-market",
    path: "/my/market",
    meta: marketeH2b04wZiQaLGaeI41zCC9zTRrW4F9g7HbVxSVGXsKIMeta || {},
    component: () => import("/vercel/path0/app/pages/my/market.vue")
  },
  {
    name: "my-review",
    path: "/my/review",
    meta: reviewzmdUzwt1o2IdOwasCzT2BdaKUP5suWhZY2EM21ukg_450Meta || {},
    component: () => import("/vercel/path0/app/pages/my/review.vue")
  },
  {
    name: "my-comment",
    path: "/my/comment",
    meta: commentQzgF9JAPmHKxP5GrYtjv8cJggXaCfFy4147qpRpkRZIMeta || {},
    component: () => import("/vercel/path0/app/pages/my/comment.vue")
  },
  {
    name: "my-meeting",
    path: "/my/meeting",
    meta: meetingyrs0gsLJLLueqqIocv6XlGA3DRGHNlyx4J23hdfWL4kMeta || {},
    component: () => import("/vercel/path0/app/pages/my/meeting.vue")
  },
  {
    name: "board-write",
    path: "/board/write",
    meta: writeOg5q0dvMGOWmW_Odcj2A3JyLd50iOKrsEkojb3kRbC8Meta || {},
    component: () => import("/vercel/path0/app/pages/board/write.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/app/pages/login/index.vue")
  },
  {
    name: "login-naver",
    path: "/login/naver",
    component: () => import("/vercel/path0/app/pages/login/naver.vue")
  },
  {
    name: "my-feedback",
    path: "/my/feedback",
    meta: feedbackvY80OWbLE9n9FOQ4FB0mNB20qSfM99JgSnvTTpcJi5AMeta || {},
    component: () => import("/vercel/path0/app/pages/my/feedback.vue")
  },
  {
    name: "point",
    path: "/point",
    meta: indexu_45W5i3JobBgLaw0ewTMhFgi8CPY4Be8wqeMl0jya9ZIMeta || {},
    component: () => import("/vercel/path0/app/pages/point/index.vue")
  },
  {
    name: "market-write",
    path: "/market/write",
    meta: writeXgKVtlXw6jq0JnZmPPLKwpiaW84dGmYSmnQMJ_mximAMeta || {},
    component: () => import("/vercel/path0/app/pages/market/write.vue")
  },
  {
    name: "policy-board",
    path: "/policy/board",
    meta: boardGPy6O31_2IA6SiLPCDdq6U6uxT56hCWHANpLvWfFNx8Meta || {},
    component: () => import("/vercel/path0/app/pages/policy/board.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexfv7fBoHtrLg7R3EtlrtAfExSzHxvoVeqhLXzNnMbO44Meta || {},
    component: () => import("/vercel/path0/app/pages/policy/index.vue")
  },
  {
    name: "policy-terms",
    path: "/policy/terms",
    meta: termsfGx4kPNFDlS64lNzei5KnJ9eATXZfFa8_8CtDEYPfA8Meta || {},
    component: () => import("/vercel/path0/app/pages/policy/terms.vue")
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    meta: _91id_934_45Ooi66zpmLy8XV5YBzjsJXWSGIgfxD9xt9av_81P9YMeta || {},
    component: () => import("/vercel/path0/app/pages/profile/[id].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexPuZtgQbFObXZ8zAW4bf6IIt86aFrik78ukTQU6ESjj8Meta || {},
    component: () => import("/vercel/path0/app/pages/search/index.vue")
  },
  {
    name: "login-welcome",
    path: "/login/welcome",
    meta: welcomegpn1W6iZJzmSxUd0NegkrFx_skEId1eOglDCBaG25ucMeta || {},
    component: () => import("/vercel/path0/app/pages/login/welcome.vue")
  },
  {
    name: "policy-market",
    path: "/policy/market",
    meta: marketjk2XZnZSvpxEEow6WxW_vVCgksA35Oc45sqtupgWFIcMeta || {},
    component: () => import("/vercel/path0/app/pages/policy/market.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index_kw1rQcClzTz_45Ni57ggQSnV1YrQxxhFpcNJAbvPf5OsMeta || {},
    component: () => import("/vercel/path0/app/pages/profile/index.vue")
  },
  {
    name: "search-result",
    path: "/search/result",
    meta: resultTe72kzvA3yGVQgEKXSaxd7M4dMwgJBOP4rC_7PMfgccMeta || {},
    component: () => import("/vercel/path0/app/pages/search/result.vue")
  },
  {
    name: "setting-alarm",
    path: "/setting/alarm",
    component: () => import("/vercel/path0/app/pages/setting/alarm.vue")
  },
  {
    name: "setting-blind",
    path: "/setting/blind",
    meta: blindjbvN7Ux6pOBsCjVdyOE1omH4VSdU86kgpNL1gJzf044Meta || {},
    component: () => import("/vercel/path0/app/pages/setting/blind.vue")
  },
  {
    name: "setting",
    path: "/setting",
    meta: indexJNKwKXFqsUkJqwguhAtv9WOtQY_45HXqWBUtZIIeXIpFYMeta || {},
    component: () => import("/vercel/path0/app/pages/setting/index.vue")
  },
  {
    name: "announce",
    path: "/announce",
    meta: index88BdM0rt4MPOSJZg_45aAd6OnBesD0G_2oD0w44X9i_OMMeta || {},
    component: () => import("/vercel/path0/app/pages/announce/index.vue")
  },
  {
    name: "policy-privacy",
    path: "/policy/privacy",
    meta: privacyQW2ZOiMhFrigisXlEfgPPU84H4kkVHlrqHop96TKh5IMeta || {},
    component: () => import("/vercel/path0/app/pages/policy/privacy.vue")
  },
  {
    name: "board-qna",
    path: "/board/qna",
    component: () => import("/vercel/path0/app/pages/board/qna/index.vue")
  },
  {
    name: "board-free",
    path: "/board/free",
    component: () => import("/vercel/path0/app/pages/board/free/index.vue")
  },
  {
    name: "login-withdrawal",
    path: "/login/withdrawal",
    component: () => import("/vercel/path0/app/pages/login/withdrawal.vue")
  },
  {
    name: "market-buy",
    path: "/market/buy",
    component: () => import("/vercel/path0/app/pages/market/buy/index.vue")
  },
  {
    name: "meeting-feedback",
    path: "/meeting/feedback",
    meta: feedbackBUPABq1fIH_O9L9H_45wFBj1vZfFamFa9gZFPFe4fiyQYMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/feedback.vue")
  },
  {
    name: "setting-security",
    path: "/setting/security",
    meta: securityXSmX32MYYpwRtWjqlQVQJuf3Cqi9YFlqrKLLvzbZ3G4Meta || {},
    component: () => import("/vercel/path0/app/pages/setting/security.vue")
  },
  {
    name: "settlement",
    path: "/settlement",
    meta: indexiEooWQZ_45ONnU1NvOza4DOqLwKPV_N1_MrjT4RrEjG2oMeta || {},
    component: () => import("/vercel/path0/app/pages/settlement/index.vue")
  },
  {
    name: "board-issue",
    path: "/board/issue",
    component: () => import("/vercel/path0/app/pages/board/issue/index.vue")
  },
  {
    name: "board-route",
    path: "/board/route",
    component: () => import("/vercel/path0/app/pages/board/route/index.vue")
  },
  {
    name: "board-total",
    path: "/board/total",
    component: () => import("/vercel/path0/app/pages/board/total/index.vue")
  },
  {
    name: "market-gear",
    path: "/market/gear",
    component: () => import("/vercel/path0/app/pages/market/gear/index.vue")
  },
  {
    name: "review-gear",
    path: "/review/gear",
    meta: indexZqOSbabISrBWKNJNHl2ju3L2Ek97mysA_8KjQQkeaN4Meta || {},
    component: () => import("/vercel/path0/app/pages/review/gear/index.vue")
  },
  {
    name: "board-riding",
    path: "/board/riding",
    component: () => import("/vercel/path0/app/pages/board/riding/index.vue")
  },
  {
    name: "board-tuning",
    path: "/board/tuning",
    component: () => import("/vercel/path0/app/pages/board/tuning/index.vue")
  },
  {
    name: "market-parts",
    path: "/market/parts",
    component: () => import("/vercel/path0/app/pages/market/parts/index.vue")
  },
  {
    name: "market-total",
    path: "/market/total",
    component: () => import("/vercel/path0/app/pages/market/total/index.vue")
  },
  {
    name: "meeting-owner-chat",
    path: "/meeting/owner/chat",
    meta: chat8qtTetzfFw7WnsUOiBo8gGpASuedeCLHubgx6tDzLJwMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/owner/chat.vue")
  },
  {
    name: "specCompare-result",
    path: "/specCompare/result",
    component: () => import("/vercel/path0/app/pages/specCompare/result.vue")
  },
  {
    name: "specCompare-search",
    path: "/specCompare/search",
    component: () => import("/vercel/path0/app/pages/specCompare/search.vue")
  },
  {
    name: "specCompare-select",
    path: "/specCompare/select",
    component: () => import("/vercel/path0/app/pages/specCompare/select.vue")
  },
  {
    name: "board-gallery",
    path: "/board/gallery",
    component: () => import("/vercel/path0/app/pages/board/gallery/index.vue")
  },
  {
    name: "board-qna-boardId",
    path: "/board/qna/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/qna/[boardId].vue")
  },
  {
    name: "meeting-owner",
    path: "/meeting/owner",
    meta: indexgjhmNgS9IgHKG_JxLARqnkd79mECdrGrR7_yhfEKPZEMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/owner/index.vue")
  },
  {
    name: "meeting-pickup-chat",
    path: "/meeting/pickup/chat",
    meta: chatCpgRfoVWwBcZP2she00k4kLNuT3pr3kCYR_45i1eJ5xD4Meta || {},
    component: () => import("/vercel/path0/app/pages/meeting/pickup/chat.vue")
  },
  {
    name: "meeting-repair-chat",
    path: "/meeting/repair/chat",
    meta: chatI1__cvIo_45bAsiVmP6_45s37SDpqtL_459EdK7ZZQjJeVvq0Meta || {},
    component: () => import("/vercel/path0/app/pages/meeting/repair/chat.vue")
  },
  {
    name: "meeting-rescue-chat",
    path: "/meeting/rescue/chat",
    meta: chat2eg0VyuWAKdx0lVk7J6xDzEQJhimYpHsJWXgKLPFdAcMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/rescue/chat.vue")
  },
  {
    name: "board-business",
    path: "/board/business",
    component: () => import("/vercel/path0/app/pages/board/business/index.vue")
  },
  {
    name: "board-free-boardId",
    path: "/board/free/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/free/[boardId].vue")
  },
  {
    name: "market-buy-boardId",
    path: "/market/buy/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/buy/[boardId].vue")
  },
  {
    name: "meeting-owner-search",
    path: "/meeting/owner/search",
    meta: searchmqWKomEAMyhdj__TbtEqXUlKAb3MAg_45WCWJLbX_45uaAIMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/owner/search.vue")
  },
  {
    name: "meeting-pickup",
    path: "/meeting/pickup",
    meta: indexsad1yqZhD0iJldGIdhp_45ybJzFxs_a6wDSvceGWDMtJMMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/pickup/index.vue")
  },
  {
    name: "meeting-repair",
    path: "/meeting/repair",
    meta: indexvZg1eHSPxmt85Z8sSbBU1om_45r7ZVqxEWjU0oqNn_458loMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/repair/index.vue")
  },
  {
    name: "meeting-rescue",
    path: "/meeting/rescue",
    meta: indexY_iT7lz52UvJ_t68mFUhLLHS8QRgNzIGi6KZ0dCsfgIMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/rescue/index.vue")
  },
  {
    name: "board-guestbook",
    path: "/board/guestbook",
    meta: indexnPDIRzMQIDXSvAcGNIPbpPd4CJOdYX8n1PZY7YyHfq4Meta || {},
    component: () => import("/vercel/path0/app/pages/board/guestbook/index.vue")
  },
  {
    name: "board-guestbook-write",
    path: "/board/guestbook/write",
    meta: writeQPNDI2yTCWdKAstMj8t7dfSrplD26GY89fHIvkHlqMAMeta || {},
    component: () => import("/vercel/path0/app/pages/board/guestbook/write.vue")
  },
  {
    name: "board-issue-boardId",
    path: "/board/issue/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/issue/[boardId].vue")
  },
  {
    name: "board-route-boardId",
    path: "/board/route/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/route/[boardId].vue")
  },
  {
    name: "board-total-boardId",
    path: "/board/total/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/total/[boardId].vue")
  },
  {
    name: "market-gear-boardId",
    path: "/market/gear/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/gear/[boardId].vue")
  },
  {
    name: "meeting-coaching-chat",
    path: "/meeting/coaching/chat",
    meta: chataZ0Ii9K33X2GAh4ePr8BFT913f2nqlC_cCi6qXlf6vcMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/coaching/chat.vue")
  },
  {
    name: "meeting-pickup-search",
    path: "/meeting/pickup/search",
    meta: searchpr_YkW2SdyBytpnAnaLQl6KxM_LG4g2R6L5p8H3IHYsMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/pickup/search.vue")
  },
  {
    name: "meeting-repair-search",
    path: "/meeting/repair/search",
    meta: search5Je6SctjvGzPNyxKSHqbyP0U_Q4sKPoUmOwBFJzX7z0Meta || {},
    component: () => import("/vercel/path0/app/pages/meeting/repair/search.vue")
  },
  {
    name: "meeting-rescue-search",
    path: "/meeting/rescue/search",
    meta: search8tB3NxaEaymmFYKiNAqDbbfOnCLNOhz43m5eHX8SOhEMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/rescue/search.vue")
  },
  {
    name: "review-motorcycle-hot",
    path: "/review/motorcycle/hot",
    component: () => import("/vercel/path0/app/pages/review/motorcycle/hot.vue")
  },
  {
    name: "board-riding-boardId",
    path: "/board/riding/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/riding/[boardId].vue")
  },
  {
    name: "board-tuning-boardId",
    path: "/board/tuning/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/tuning/[boardId].vue")
  },
  {
    name: "market-parts-boardId",
    path: "/market/parts/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/parts/[boardId].vue")
  },
  {
    name: "market-total-boardId",
    path: "/market/total/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/total/[boardId].vue")
  },
  {
    name: "meeting-coaching",
    path: "/meeting/coaching",
    meta: indexO7PVxPqMUQM6Vn5i6XFE7aFfskW7mcCRr7frnIVpqbEMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/coaching/index.vue")
  },
  {
    name: "review-motorcycle-list",
    path: "/review/motorcycle/list",
    component: () => import("/vercel/path0/app/pages/review/motorcycle/list.vue")
  },
  {
    name: "board-gallery-boardId",
    path: "/board/gallery/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/gallery/[boardId].vue")
  },
  {
    name: "board-reportTheft",
    path: "/board/reportTheft",
    component: () => import("/vercel/path0/app/pages/board/reportTheft/index.vue")
  },
  {
    name: "board-vehicleCare",
    path: "/board/vehicleCare",
    component: () => import("/vercel/path0/app/pages/board/vehicleCare/index.vue")
  },
  {
    name: "meeting-coaching-search",
    path: "/meeting/coaching/search",
    meta: searchfuYWOKX_45xHFwlIofDaFRBC5QBGSg7LmMCzR_45mu64s2oMeta || {},
    component: () => import("/vercel/path0/app/pages/meeting/coaching/search.vue")
  },
  {
    name: "report-motorcycle",
    path: "/report/motorcycle",
    meta: indexmTH4kDdn5f1iUOMW5R81f89yvsLW92DTlGLREt1AqLgMeta || {},
    component: () => import("/vercel/path0/app/pages/report/motorcycle/index.vue")
  },
  {
    name: "announce-tab-boardId",
    path: "/announce/:tab()/:boardId()",
    component: () => import("/vercel/path0/app/pages/announce/[tab]/[boardId].vue")
  },
  {
    name: "board-business-boardId",
    path: "/board/business/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/business/[boardId].vue")
  },
  {
    name: "review-motorcycle-detail",
    path: "/review/motorcycle/detail",
    component: () => import("/vercel/path0/app/pages/review/motorcycle/detail.vue")
  },
  {
    name: "settlement-securityCheck",
    path: "/settlement/securityCheck",
    meta: securityCheckpuoiT79gZfsAr18K2b5Nb8Lt_LuvqfbgY0xA144LbEkMeta || {},
    component: () => import("/vercel/path0/app/pages/settlement/securityCheck.vue")
  },
  {
    name: "board-guestbook-boardId",
    path: "/board/guestbook/:boardId()",
    meta: _91boardId_93mDFaYD71L_45hwjyX7gDYtAzKU1S4RlHEz0DmV9TASRu4Meta || {},
    component: () => import("/vercel/path0/app/pages/board/guestbook/[boardId].vue")
  },
  {
    name: "market-deliveryGear",
    path: "/market/deliveryGear",
    component: () => import("/vercel/path0/app/pages/market/deliveryGear/index.vue")
  },
  {
    name: "market-over125Manual",
    path: "/market/over125Manual",
    component: () => import("/vercel/path0/app/pages/market/over125Manual/index.vue")
  },
  {
    name: "review-motorcycle-my",
    path: "/review/motorcycle/my",
    meta: indexkq2wt3ql4JabFtBRcAvDYDfWqJweMuHeolG4vdG8U08Meta || {},
    component: () => import("/vercel/path0/app/pages/review/motorcycle/my/index.vue")
  },
  {
    name: "board-reportTheft-boardId",
    path: "/board/reportTheft/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/reportTheft/[boardId].vue")
  },
  {
    name: "board-vehicleCare-boardId",
    path: "/board/vehicleCare/:boardId()",
    component: () => import("/vercel/path0/app/pages/board/vehicleCare/[boardId].vue")
  },
  {
    name: "market-over125Scooter",
    path: "/market/over125Scooter",
    component: () => import("/vercel/path0/app/pages/market/over125Scooter/index.vue")
  },
  {
    name: "market-under125Manual",
    path: "/market/under125Manual",
    component: () => import("/vercel/path0/app/pages/market/under125Manual/index.vue")
  },
  {
    name: "market-under125Scooter",
    path: "/market/under125Scooter",
    component: () => import("/vercel/path0/app/pages/market/under125Scooter/index.vue")
  },
  {
    name: "review-motorcycle-write-init",
    path: "/review/motorcycle/write/init",
    meta: initKK5Ul_YVHl4M5ejGyTgppN0u8vXmRkWCl0qkDC_451qeIMeta || {},
    component: () => import("/vercel/path0/app/pages/review/motorcycle/write/init.vue")
  },
  {
    name: "market-deliveryGear-boardId",
    path: "/market/deliveryGear/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/deliveryGear/[boardId].vue")
  },
  {
    name: "review-motorcycle-write",
    path: "/review/motorcycle/write",
    meta: index_45vACxsJad0hd9_YuF_450upPMlW8rUjSURqn16IVz3KOgMeta || {},
    component: () => import("/vercel/path0/app/pages/review/motorcycle/write/index.vue")
  },
  {
    name: "market-over125Manual-boardId",
    path: "/market/over125Manual/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/over125Manual/[boardId].vue")
  },
  {
    name: "review-motorcycle-search",
    path: "/review/motorcycle/search",
    component: () => import("/vercel/path0/app/pages/review/motorcycle/search/index.vue")
  },
  {
    name: "review-motorcycle-write-search",
    path: "/review/motorcycle/write/search",
    meta: searchoJ7tcTCiufPxuj1rMbiHsRahuTyWVNTTZBOOk86ClJ4Meta || {},
    component: () => import("/vercel/path0/app/pages/review/motorcycle/write/search.vue")
  },
  {
    name: "market-over125Scooter-boardId",
    path: "/market/over125Scooter/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/over125Scooter/[boardId].vue")
  },
  {
    name: "market-under125Manual-boardId",
    path: "/market/under125Manual/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/under125Manual/[boardId].vue")
  },
  {
    name: "review-motorcycle-search-result",
    path: "/review/motorcycle/search/result",
    component: () => import("/vercel/path0/app/pages/review/motorcycle/search/result.vue")
  },
  {
    name: "review-motorcycle-write-confirm",
    path: "/review/motorcycle/write/confirm",
    meta: confirmyJ8etEZvIRvl6s799wHRikQ5PpBB6tRVccdHJFx5KBUMeta || {},
    component: () => import("/vercel/path0/app/pages/review/motorcycle/write/confirm.vue")
  },
  {
    name: "market-under125Scooter-boardId",
    path: "/market/under125Scooter/:boardId()",
    component: () => import("/vercel/path0/app/pages/market/under125Scooter/[boardId].vue")
  }
]