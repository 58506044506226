import { defineStore } from 'pinia'

export const useBlindDataStore = defineStore('blindDataStore', () => {
  /**
   * ! Pinia State !
   *
   * @param blindUserIds 블라인드 유저 ID 목록
   *
   */

  const blindUserIds = ref<string[]>([])

  return {
    blindUserIds,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
})
