export default {
  seoDescription: [
    '내 근처 바이크 정비/구난/탁송 전문가와 차종별 오너를 만나보세요. 다른 라이더에게 도움을 주고 수익을 창출해 보세요. 라이더들의 상호작용을 돕습니다.',
    'Connect with local motorcycle repair, rescue, and transportation experts and riders who ride the motorbike model you\'re interested in. Offer help to other riders and earn along the way. Built to enhance rider-to-rider interactions.',
  ],
  seoKeywords: [
    '바이크 시승기',
    '바이크 리뷰',
    '오토바이 시승기',
    '오토바이 리뷰',
    '슈퍼커브 110',
    'Z900rs',
    '캔암 라이커',
    '할리 FLHS',
    '할리 일렉트라 글라이드',
    '스바르트필렌 801',
    '필렌 801',
    'CB125R',
    'CRF300L',
    'MT-03',
    'CRF250',
    'PCX',
    '스바르트필렌 250',
    '필렌 250',
    'S1000RR',
    'XL1200CX',
    '하야부사',
    '하야부사 2세대',
    '하야부사 3세대',
    '헌터커브',
    'XL750',
    '트랜잘프',
    'R1250R',
    'GSX-8R',
    'GSX-R125',
    'FLHX 스트리트 글라이드',
    '스트리트 글라이드',
    '스글',
    'CB500X',
    'MSX 125',
    '엠애기',
    'ZZR1400',
    '스바르트필렌 401',
    'CL500',
    'Z H2',
    'CBR500R',
    'K1600GT',
    'V-Strom',
    'V-Strom 1000XT',
    '대왕부리',
    'CB1000R',
    '닌자 1000SX',
    '닌숙이',
    'CB500X',
    'CRF1000L',
    '아프리카트윈',
    '390듀크',
    'Duke 390',
    'CB650F',
    'K1200GT',
    'CBR600RR PC40',
    'CBR600RR 2025년식',
    'R1250GS',
    '닌자 H2 SX',
    'R1250RT',
    'GSX-S1000',
    '닌자 650',
    '닌육반',
    '닌자 1000',
    'R1200RT',
    '쓰럭스톤',
    'Thruxton',
    'CBR650F',
    'F850GS',
    'V-Strom 650XT',
    '부리육반',
    'G650GS',
    'R1200GS',
    '캔암 스파이더',
    'zx-6r',
    '닌자 6R',
    'GSX-S750',
    '비트필렌 701',
    '필렌 701',
    '1390듀크',
    '1390Duke',
    'MT-09',
    '혼다 CL500',
    'MT-09 트레이서',
    '야마하 트레이서',
    'REBEL 1100',
    'YZF-R3',
    'CB650R',
    '혼다 이클러치 시승기',
    'ZX-25R',
    'GTS 125',
    '스포츠 글라이드',
    'FLSB',
    '소프테일',
    '바이크 정비',
    '오토바이 정비',
    '바이크 자가정비',
    '오토바이 자가정비',
    '바린이',
    '바이크 입문',
    '오토바이 입문',
    '오토바이교육',
    '라이딩스쿨',
    '바이크 교육',
    '아퀼라125',
    '엔맥스',
    'NMAX',
    '티맥스',
    'TMAX',
    'T-MAX',
    '중고바이크',
    '중고오토바이',
    '바이크 견적',
    '오토바이 견적',
    '바이크 수리',
    '바이크 수리 견적',
    '오토바이 수리',
    '오토바이 수리 견적',
    '바이크 용달',
    '오토바이 용달',
    '바이크 탁송',
    '오토바이 탁송',
    '125cc바이크',
    '2종소형면허',
    '골드윙',
    '골드윙1800',
    '오토바이센터',
    '바이크센터',
    '바이크 타이어 교체',
    '오토바이 타이어 교체',
    '오토바이 타이어',
    '파쏘오토바이',
    '혼다오토바이',
    '야마하오토바이',
    '스즈키오토바이',
    '가와사키오토바이',
    '혼다바이크',
    '야마하바이크',
    '스즈키바이크',
    '가와사키바이크',
    'BMW오토바이',
    'BMW바이크',
    'wheelsmeets',
    '휠즈앤밋츠',
    '휠밋',
    '모터사이클',
    '오토바이',
    '할리데이비슨',
    'motorcycle',
    'harleydavidson',
    'harley',
    'honda',
    '혼다',
    'ducati',
    '두카티',
    'bmw',
    '비엠더블유',
    'ktm',
    '케이티엠',
    'yamaha',
    '야마하',
    'royalenfield',
    '로얄엔필드',
    '커스텀',
    '커스텀바이크',
    'custombike',
  ],
}
