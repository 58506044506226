import { defu } from "defu";
import {
  useNuxtApp,
  useRequestEvent
} from "nuxt/app";
import { reactive, watchEffect } from "vue";
export function useSiteConfig(options) {
  const stack = import.meta.server ? useRequestEvent()?.context.siteConfig.get(defu({ resolveRefs: true }, options)) : reactive({});
  if (import.meta.client) {
    watchEffect(() => {
      const data = useNuxtApp().$nuxtSiteConfig.get(defu({ resolveRefs: true }, options));
      Object.assign(stack, data);
    });
  }
  delete stack._priority;
  return stack;
}
