export const useLoadBlindData = () => {
  const user = useSupabaseUser()

  const { blindUserIds } = storeToRefs(useBlindDataStore())

  const { execute: executeBlindList } = useLazyAsyncData('blindList', async () => {
    if (!user.value?.id) {
      blindUserIds.value = []
      return []
    }

    const { data: response } = await useFetch('/api/setting/blind', {
      headers: useRequestHeaders(['cookie']),
      transform: (payload: Database['public']['Tables']['blindList']['Row'][]) => {
        return payload.map((blind) => {
          return blind.activated
            ? blind.blind_user_id ?? ''
            : ''
        })
      },
    })

    blindUserIds.value = response.value as string[]

    return response.value as string[]
  })

  return {
    executeBlindList,
  }
}
